export default class Perso {
  constructor(nom, position) {
    this.nom = nom;
    this.position = position;
  }

  setPosition(nouvellePosition) {
    if(nouvellePosition > 7) {
      this.position = 7;
    } else if(nouvellePosition < 0) {
      this.position = 0;
    } else {
      this.position = nouvellePosition;
    }
  }
}