import React from "react";
import { AppBar, Toolbar, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  signature: {
    justifyContent: "center",
  },
}));

function Footer() {
  const classes = useStyles();

  return (
    <div>
      <AppBar color="secondary" position="static">
        <Toolbar className={classes.signature}>
          <Typography variant="subtitle2" color="inherit">
            Made by © Thelos Aeterna Company.
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Footer;
