import React from "react";
import {
  Box,
  Card,
  makeStyles,
  Typography,
  FormControl,
  Button,
  FormHelperText,
  TextField,
  NativeSelect,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.primary,
    margin: "10px",
    marginTop: "0px",
  },
  titleGestionPerso: {
    marginBottom: "10px",
  },
  upperBox: {
    borderBottom: "1px solid black",
  },
  button: {
    width: "90%",
  },
  form: {
    marginBottom: "20px",
  },
}));

/**
 * @param {array} props.persos An array containing all the characters currently in the weel
 * @param {function} props.handleAction A function changing the position of a character according to his action
 */
function PersoManager(props) {
  const classes = useStyles();
  // Store the characted who is currently doing something
  const [persoSelected, setPersoSelected] = React.useState("");
  // Store the value of the action made by character
  const [actionValue, setActionValue] = React.useState("");

  /**
   * @description Change the character stored in state according to the <select> form
   */
  const handleChangePerso = (event) => {
    setPersoSelected(event.target.value);
  };

  /**
   * @description Change the action value stored in state according to the right <input> form
   */
  const handleChangeActionValue = (event) => {
    const checkPoint = /^[0-9]$/;
    if (checkPoint.test(Number(event.target.value))) {
      setActionValue(event.target.value);
    } else if (!event.target.value) {
      return null;
    } else {
      alert("Attention, veuillez entrer un seul chiffre");
      event.target.value = 0;
    }
  };

  // Exists beacause I don't know how to directly use handleAction in my onClick on the button
  const midHandlerAction = () => {
    if (persoSelected) {
      props.handleAction(persoSelected, Number(actionValue));
    } else {
      alert("Attention, aucun perso n'est sélectionné");
    }
  };

  // Exists beacause I don't know how to directly use handleAction in my onClick on the button
  const midHandlerDelete = () => {
    if (persoSelected) {
      props.handleDelete(persoSelected, Number(actionValue));
    } else {
      alert("Attention, aucun perso n'est sélectionné");
    }
  };

  return (
    <Card className={classes.main} elevation={3}>
      <Box className={classes.upperBox}>
        <Typography variant="h5" className={classes.titleGestionPerso}>
          Gestion de perso
        </Typography>
        <FormControl className={classes.form}>
          <NativeSelect
            value={persoSelected}
            name="Selectionnez un perso"
            onChange={handleChangePerso}
          >
            {" "}
            {/* For each character stored in the state, add one option to the <select> balise */}
            <option value="" key="0">
              None
            </option>
            {props.listePersos.map((perso) => (
              <option value={perso.nom} key={perso.nom}>
                {perso.nom}
              </option>
            ))}
            {/* End of the options generator */}
          </NativeSelect>
          <FormHelperText>Selectionnez un personnage</FormHelperText>
        </FormControl>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField id="Action" label="Action"></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="Cout"
            label="Cout"
            onChange={handleChangeActionValue}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={midHandlerAction}
            color="secondary"
          >
            Agir
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={midHandlerDelete}
            color="secondary"
          >
            Detruire
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}

export default PersoManager;
