import React from "react";
import { Button, Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "center",
  },
}));

function ForwardAll(props) {
  const classes = useStyles();

  return (
    <Box>
      <Button
        className={classes.container}
        variant="contained"
        onClick={props.handlerForward}
        color="secondary"
      >
        Finir le tour
      </Button>
    </Box>
  );
}

export default ForwardAll;
