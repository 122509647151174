import React from "react";
import { Box, Card, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  friezePart: {
    padding: theme.spacing(2),
    textAlign: "center",
    border: "solid black 1px",
    minHeight: "100px",
    minWidth: "200px",
    maxWidth: "8.33%",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "center",
  },
}));

function Frieze(props) {
  const classes = useStyles();
  const frieze = [
    { value: 7 },
    { value: 6 },
    { value: 5 },
    { value: 4 },
    { value: 3 },
    { value: 2 },
    { value: 1 },
    { value: 0 },
  ];

  return (
    <Box className={classes.container}>
      {frieze.map((part) => {
        return (
          <Card className={classes.friezePart} key={`f${part.value}`}>
            {part.value}
            {props.listePersos.map((perso) => {
              if (perso.position === part.value) {
                return (
                  <Typography key={perso.nom} variant="body1">
                    {perso.nom}
                  </Typography>
                );
              }
              return undefined;
            })}
          </Card>
        );
      })}
    </Box>
  );
}

export default Frieze;
