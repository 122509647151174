import React, { useState } from "react";
import {
  Box,
  Card,
  makeStyles,
  Typography,
  Button,
  TextField,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.primary,
    margin: "10px",
    marginTop: "0px",
    height: "87%",
  },
  titleGestionPerso: {
    marginBottom: "13.5%",
  },
  upperBox: {
    borderBottom: "1px solid black",
  },
  button: {
    width: "90%",
  },
  form: {
    marginBottom: "20px",
  },
}));

function PersoGenerator(props) {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [position, setPosition] = useState(0);

  const handlerChangeName = (event) => {
    setName(event.target.value);
  };

  const handlerChangePosition = (event) => {
    setPosition(event.target.value);
  };

  const midGenerate = () => {
    if (name && position) {
      props.generate(name, Number(position));
    } else {
      alert("Attention, les deux champs doivent être complétés correctement");
    }
  };

  return (
    <Card className={classes.main} elevation={3}>
      <Box className={classes.upperBox}>
        <Typography variant="h5" className={classes.titleGestionPerso}>
          Création de perso
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            id="Nom"
            label="Nom"
            onChange={handlerChangeName}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="Position"
            label="Position"
            onChange={handlerChangePosition}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            className={classes.button}
            color="secondary"
            onClick={midGenerate}
          >
            Créer
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}

export default PersoGenerator;
