import React from 'react';
import "./App.css";
import MainInterface from "./components/MainInterface"
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: {
      main: '#1565c0',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MainInterface/>
    </ThemeProvider>
  );
}

export default App;
