import React, { useState, useCallback } from "react";
// Importing externals compomnents
import { Grid, Box, makeStyles } from "@material-ui/core";
// Importing hand-made components
import Header from "./Header";
import Footer from "./Footer";
import Frieze from "./Frieze";
import PersoManager from "./PersoManager";
import PersoGenerator from "./PersoGenerator";
import ForwardAll from "./ForwardAll";
// Importing hand-made classes
import Perso from "../classes/Perso";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#e0e0e0",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.primary,
    margin: "10px",
    marginTop: "0px",
    height: "87%",
  },
  main: {
    display: "flex",
    justifyContent: "center",
  },
  titleGestionPerso: {
    marginBottom: "10px",
  },
}));

const persoTest1 = new Perso("testThelos", 3);
const persoTest2 = new Perso("testIris", 5);

function MainInterface() {
  const classes = useStyles();
  const [persos, setPersos] = useState([persoTest1, persoTest2]);

  /**
   * @description Add -1 to all the characters' position
   */
  function moveForward() {
    let l = [...persos];
    l.forEach((perso) => {
      perso.setPosition(perso.position - 1);
    });
    setPersos(l);
  }

  /**
   * @description Change a character's position to a new one because of his action
   * @param {string} persoDoer The name of the perso who is doing action
   * @param {number} newPosition The new position of the perso afther his action
   */
  const actionHandler = useCallback((persoDoer, newPosition) => {
    setPersos((prev) => {
      const l = [...prev];
      const persoWanted = l.find((perso) => perso.nom === persoDoer);
      persoWanted.setPosition(newPosition);
      return l;
    });
  }, []); // Mieux documenter cette manière de faire, se renseigner

  /**
   * @description Change a character's position to a new one because of his action
   * @param {string} persoDoer The name of the perso who is doing action
   * @param {number} newPosition The new position of the perso afther his action
   */
  const deleteHandler = useCallback((persoDoer, newPosition) => {
    setPersos((prev) => {
      const l = [...prev];
      const persoWanted = l.find((perso) => perso.nom === persoDoer);
      l.splice(l.indexOf(persoWanted), 1);
      return l;
    });
  }, []); // Mieux documenter cette manière de faire, se renseigner

  /**
   * @description create a new perso on the battlefield
   */
  const createPerso = useCallback((name, position) => {
    setPersos((prev) => {
      const l = [...prev];
      if (l.find((perso) => perso.nom === name)) {
        alert("Ce nom de perso est déjà pris");
        return l;
      }
      const nouveauPerso = new Perso(name, position);
      l.push(nouveauPerso);
      return l;
    });
  }, []);

  return (
    <Box className={classes.root}>
      <Header />
      <Grid container spacing={3} className={classes.main}>
        <Grid item xs={12}>
          <Frieze className={classes.paper} listePersos={persos} />
        </Grid>
        <Grid item xs={4}>
          <PersoGenerator className={classes.paper} generate={createPerso} />
        </Grid>
        <Grid item xs={4}>
          <ForwardAll className={classes.paper} handlerForward={moveForward}>
            Bouton avancer tous
          </ForwardAll>
        </Grid>
        <Grid item xs={4}>
          <PersoManager
            listePersos={persos}
            handleAction={actionHandler}
            handleDelete={deleteHandler}
          />
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
}

export default MainInterface;
