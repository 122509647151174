import React from "react";
import { AppBar, Toolbar, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: "20px",
  },
}));

function Header() {
  const classes = useStyles();

  return (
    <div>
      <AppBar color="secondary" position="static" className={classes.title}>
        <Toolbar>
          <Typography variant="h3" color="inherit">
            Scions - Roue de Combat
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;
